<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Situação depois do rompimento da barragem de Fundão"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Antes do rompimento da barragem, Camargos fazia parte do
                circuito turístico da região, que incluía o subdistrito de Bento
                Rodrigues. Os visitantes eram atraídos principalmente pelas
                trilhas, cachoeiras, rios e pela comida servida nos restaurantes
                de fazendas, bem como nos núcleos urbanos de Camargos e Bento.
                Com a severa destruição sofrida por Bento Rodrigues, que foi
                completamente destruída pela lama de rejeitos, as atividades
                advindas dessa interação foram cessadas. A lama interrompeu o
                percurso da Estrada Real.
              </p>
              <p>
                O acesso a Bento foi perdido, incluindo uma ponte centenária que
                ali havia, e o trajeto de visitantes passou a ficar restrito ao
                caminho percorrido pelo sul do território. As pessoas passaram a
                ter medo de ir para Camargos, incluindo turistas que se
                hospedavam em pousadas do distrito. O número de visitantes foi
                drasticamente reduzido, tendo em vista que o meio ambiente
                circundante foi alcançado e destruído pela lama, chegando até a
                cachoeira na divisa com Camargos. Reduziram-se, assim, os
                visitantes da comunidade e compradores dos produtos fabricados
                por moradores, a exemplo de doces, sendo que alguns passaram a
                comercializar na feira noturna de Mariana.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_24.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista de ponto da estrada que conduz ao distrito de Camargos, onde
            se encontra um totem de sinalização da Estrada Real. Foto: Flora
            Passos, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_25.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista de vegetação arbórea em segundo plano, situadas na margem do
            Rio Gualaxo na região de Camargos, que está secando após o
            rompimento da barragem. Em primeiro plano, curso d’água em meio ao
            acúmulo de rejeitos com vegetação invasora sobre o solo natural.
            Foto: Flora Passos, 2016.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O distrito de Camargos, desde o rompimento da barragem, vem
                sofrendo evasão de seus moradores que se mudaram para outros
                locais em busca de segurança e de oportunidades de emprego.
                Muitos trabalhavam em sítios e fazendas da região que foram
                alcançadas parcial ou totalmente pela lama e tiveram as
                atividades produtivas em parte ou completamente perdidas.
              </p>
              <p>
                Somam-se a elas os terrenos da comunidade que não foram
                destruídos, mas desde então têm a produção sendo reduzida tanto
                pela falta de mão de obra, como de fornecedores, bem como pela
                dificuldade de acesso. Diante disso, muitos produtores
                paralisaram suas atividades, e o distrito, que se depara com o
                isolamento econômico e social, encontra-se em situação de
                empobrecimento e decadência econômica.
              </p>
              <p>
                Conforme relatos de moradores, depois do rompimento, a população
                do distrito perdeu a tranquilidade, e na época surgiram muitos
                curiosos, além de casos de pessoas que apareciam para saquear,
                sendo furtadas até mesmo peças do sistema de telefonia. A
                população ficou desamparada, visto que a polícia de Mariana não
                atende Camargos e eles dependem de Santa Rita Durão, cujo acesso
                mais próximo foi tomado pela lama de rejeitos. A defesa civil,
                no princípio, comunicava que os moradores deveriam sair da
                comunidade, mas eles permaneciam para impedir que seus terrenos
                fossem saqueados. Havia trânsito intenso do corpo de bombeiros,
                e a ponte da cachoeira havia se rompido. Toda essa movimentação
                causava preocupação e insegurança nos moradores de Camargos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Apesar dos esforços dos moradores que resistem na comunidade, o
                enfraquecimento da economia, somado ao isolamento, ao receio de
                um novo rompimento de barragem, alertado pelas placas de
                sinalização de rota de fuga e área de risco juntamente com a
                sirene instalada em Camargos, acarreta na desvalorização
                imobiliária e na fuga de possíveis compradores tanto da
                produção, quanto dos imóveis do local.
              </p>
              <p>
                A comunidade esquecida após o desastre perdeu a ponte que a
                ligava à Estrada Real e viu os negócios minguarem. A estrutura
                que havia em Bento Rodrigues atendia também aos moradores de
                Camargos, a exemplo do transporte público, indisponível no
                distrito. As linhas iam somente até Bento, e os moradores de
                Camargos se dirigiam até a comunidade vizinha para ter acesso
                aos ônibus.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_26.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista do Rio Gualaxo do Norte em trecho da região de Camargos.
            Observam-se espécies arbóreas secas em meio à vegetação. Fotos:
            Flora Passos, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="4" class="d-flex justify-start align-start">
          <img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_27.png"
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <div class="d-flex flex-column justify-space-between poem">
            <p style="font-size: 1.2em;"><strong>Poema</strong></p>

            <p>
              <span>CAMARGOS </span>
              <span>ACONCHEGANTE </span>
              <span>PEQUENINO! </span>
              <span>PARECE UM PRESEPINHO, CHEIO DE AMOR E CARINHO. </span>
            </p>
            <p>
              <span>TEM TANTAS BELEZAS, TANTAS! </span>
              <span>O CRUZEIRO! </span>
              <span>A IGREJINHA, LÁ NO ALTO DA COLINA, </span>
              <span>DA NOSSA MÃE E RAINHA.</span>
            </p>
            <p>
              <span>A ESTRADA É DE CHÃO </span>
              <span>NÃO TEM ASFALTO, NÃO </span>
              <span>MAS, TEM FLORES, CACHOEIRA </span>
              <span>E CICLISTAS CURTINDO ESTE CHÃO. </span>
            </p>
            <p>
              <span>CAMARGOS TEM SUA HISTÓRIA </span>
              <span>AS RUÍNAS DO TESOUREIRO, A ESTRADA REAL </span>
              <span>QUE LHE DEU REALEZA E GLÓRIA. </span>
            </p>
            <p>
              <span>CAMARGOS É TERRA DE AMORES </span>
              <span>CHEIA DE LINDAS FLORES, </span>
              <span>DE UM CÉU AZUL COR DE ANIL </span>
              <span>DE BRISA LEVE E SUAVE </span>
              <span>DE NOITES ENLUARADAS </span>
              <span>CHEIAS DE ENCANTOS MIL. </span>
            </p>

            <p style="font-size: 0.7em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p class="text-center">
                <strong>Autora : Ana Paula Alves Ferreira <sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
